@import "bootstrap/scss/variables";

@mixin pdpSwatchCircleUnselectable {
    cursor: not-allowed;

    &.color-value {
        &.selected {
            &::after {
                background-color: $gray-700;
            }
        }
    }
}

.product-detail {
    [disabled] {
        .swatch-circle {
            @include pdpSwatchCircleUnselectable;
        }
    }

    .swatch-circle {
        .unselectable {
            @include pdpSwatchCircleUnselectable;
        }
    }
}

label {
    &.color~a {
        &:hover {
            text-decoration: none;
        }
    }

    &.availability {
        padding-right: .3125em;
    }
}

.description-and-detail {
    margin-top: 2em;

    @include media-breakpoint-only(xs) {
        margin-top: 0;

        .title {
            font-family: $primary-font;
            font-size: 1.5rem;
            margin-bottom: 0;
        }

        >div {
            &:last-child {
                margin-bottom: 1em;
            }
        }
    }

    .description {

        @include media-breakpoint-up(sm) {
            margin-bottom: 2em;
        }
    }

    .content {

        @include media-breakpoint-only(xs) {
            margin-top: 1em;
        }
    }
}

.social-container {
    padding-top: rem-calc(2);
    padding-bottom: rem-calc(9);

    @include media-breakpoint-up(md) {
        padding-bottom: 0;
    }
}

@include media-breakpoint-up(sm) {
    .product-number-rating {
        border-bottom: 1px solid $hr-border-color;
    }
}

@include media-breakpoint-up(md) {
    .product-number-rating {
        border-top: 1px solid $hr-border-color;
    }
}

@include media-breakpoint-only(sm) {
    .product-name {
        border-bottom: 1px solid $hr-border-color;
    }
}

.ratings {
    @include media-breakpoint-up(sm) {
        margin-top: .625em;
    }

    @include media-breakpoint-only(xs) {
        background-color: $white;
        margin-right: -.9375em;
        margin-top: -1.875em;
        padding: .1875em;
    }
}

.product-number {
    color: $product-number-grey;
    font-size: .875em;
    padding-bottom: .938em;
    padding-top: .938em;
}

.promotions {
    font-weight: normal;
    height: 85%;
    text-align: center;

    .collapsible-xl {
        button {
            &.callout {
                color: $danger;
                font-size: 1.25em;
                padding-bottom: 1.25rem;
                padding-top: 1.25rem;

                @include media-breakpoint-up(sm) {
                    font-size: 1.375em;
                }

                &::after {
                    color: $black;
                }
            }
        }
    }
}

.product-breadcrumb {
    .breadcrumb {
        border-bottom: 0;
        margin-bottom: 0;
        margin-left: -1rem;

        @include media-breakpoint-down(md) {
            border-top: 1px solid $grey3;
        }
    }
}

.price {
    font-size: 1.5rem;
}

.product-options {
    margin-top: 1.25em;
}

.size-chart {
    .size-chart-collapsible {
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s;
        transition-timing-function: cubic-bezier(0, 1, 0, 1);

        &.active {
            max-height: 100%;
            transition-timing-function: ease-in-out;
        }
    }
}

.color-attribute {
    background: none;
    border: 0;
    padding: 0;
}

.non-input-label {
    display: block;
    margin-bottom: .5rem;
}

.attributes {

    @include media-breakpoint-up(md) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
}

.cc-header__tag__scrollDown + #maincontent {
    @include media-breakpoint-up(sm) {
        .cc-product-detail .cc-pdp-row .cc-pdp-col-description__wrp {
            top: 0;
        }
    }
}
