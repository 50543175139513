/* ==========================================================================

                        C O N F I G U R A T I O N

========================================================================== */

/* ===========================
        FONTS
=========================== */
$primary-font: "DM Sans", sans-serif;
$primary-font-regular: 400;
$primary-font-medium: 500;
$primary-font-semibold: 600;
$primary-font-bold: 700;
$primary-font-extra-bold: 800;
$secondary-font: "Antonio", sans-serif;
$secondary-font-bold: 700;

// Font default Bootstrap
$font-family-base: $primary-font;

// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../fonts" !default;

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/" !default;



/* ===========================
        BOOTSTRAP GRID
=========================== */
$grid-breakpoints: (
    xxs: 0,
    xs: 375px,
    sm: 768px,
    md: 1024px,
    lg: 1200px,
    xl: 1440px) !default;

$container-max-widths: (xl: 1440px) !default;



/* ===========================
        MIXIN AND FUNCTION
=========================== */

// rem conversion
@function rem-calc($pixelToConvert) {
    $remResult: $pixelToConvert * .0625;
    @return $remResult+rem;
}

// Animation
@mixin animation {
    -moz-transition: all .15s ease-in-out;
    -ms-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
}

// Animation Slow
@mixin animationSlow {
    -moz-transition: all .35s ease-in-out;
    -ms-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    -webkit-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out;
}

// Animation Super Slow
@mixin animationSuperSlow {
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

// NO Animation
@mixin noAnimation {
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
}

// Position Center X
@mixin centerX {
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}

// Position Center Y
@mixin centerY {
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

// Position Center XY
@mixin centerXY {
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
}

// NO Position Center X
@mixin noCenterX {
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    -webkit-transform: none;
    left: 0;
    position: relative;
    transform: none;
}

// NO Position Center Y
@mixin noCenterY {
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    -webkit-transform: none;
    position: relative;
    top: 0;
    transform: none;
}

// NO Position Center XY
@mixin noCenterXY {
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    -webkit-transform: none;
    left: 0;
    position: relative;
    top: 0;
    transform: none;
}

// Border Radius
@mixin borderRadius ($borderRadius) {
    -moz-border-radius: $borderRadius;
    -webkit-border-radius: $borderRadius;
    border-radius: $borderRadius;
}

// Box Shadow
@mixin boxShadow ($boxShadow) {
    -moz-box-shadow: $boxShadow;
    -webkit-box-shadow: $boxShadow;
    box-shadow: $boxShadow;
}

// Blur
@mixin blur ($pixelBlur) {
    -moz-filter: blur($pixelBlur);
    -ms-filter: blur($pixelBlur);
    -o-filter: blur($pixelBlur);
    -webkit-filter: blur($pixelBlur);
}

// Placeholder
@mixin inputPlaceholder ($colorPlaceholder, $opacityPlaceholder) {
    &.placeholder {
        color: $colorPlaceholder;
        opacity: $opacityPlaceholder;
    }

    &:-moz-placeholder {
        color: $colorPlaceholder;
        opacity: $opacityPlaceholder;
    }

    &::-moz-placeholder {
        color: $colorPlaceholder;
        opacity: $opacityPlaceholder;
    }

    &:-ms-input-placeholder {
        color: $colorPlaceholder;
        opacity: $opacityPlaceholder;
    }

    &::-webkit-input-placeholder {
        color: $colorPlaceholder;
        opacity: $opacityPlaceholder;
    }
}

// Replace for SVG in CSS
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@mixin flexGenerator($direction, $primary: flex-start, $secondary: stretch, $grow: 1, $shrink: 0, $basis: auto) {
    -webkit-align-content: $secondary;
    -webkit-align-items: $secondary;
    -webkit-flex-direction: $direction;
    -webkit-justify-content: $primary;
    align-content: $secondary;
    align-items: $secondary;
    display: flex;
    flex: $grow $shrink $basis;
    flex-direction: $direction;
    justify-content: $primary;
}

@mixin flexGeneratorContainer($direction: row, $justifyContent: flex-start, $alignItems: flex-start, $flexWrap: nowrap, $columnGap: 0, $rowGap: 0) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justifyContent;
    align-items: $alignItems;
    flex-wrap: $flexWrap;

    @if $columnGap != 0 {
        column-gap: rem-calc($columnGap);
    }

    @if $rowGap != 0 {
        row-gap: rem-calc($rowGap);
    }
}

@mixin fixHeight($height) {
    height: $height;
    max-height: $height;
    min-height: $height;
}

@mixin fixWidth($width) {
    max-width: $width;
    min-width: $width;
    width: $width;
}

@mixin truncateRow($numRow: 1) {
    overflow: hidden;
    text-overflow: ellipsis;

    @if $numRow>1 {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $numRow;
        display: -webkit-box;
    } @else {
        white-space: nowrap;
    }
}

@mixin discount-price-diagonal {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 100 100"><path d="M0 99 L99 0 L100 1 L1 100" fill="currentColor"/></svg>');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%, auto;
}

@mixin discount-price-diagonal-strong {
    display: inline-block;
    position: relative;

    &::before {
        border-bottom: rem-calc(1) solid $black;
        content: '';
        height: 30%;
        left: 50%;
        position: absolute;
        transform: rotate(-8deg) translateX(-50%);
        width: 120%;
    }
}

@mixin text-style($textColor: black, $fontSizePx: 16, $fontWeight: $primary-font-regular, $lineHeightPx: 21, $textAlign: initial, $textTransform: none, $textDecoration: none) {
    color: $textColor;
    font-size: rem-calc($fontSizePx);
    font-weight: $fontWeight;
    line-height: rem-calc($lineHeightPx);
    text-align: $textAlign;
    text-transform: $textTransform;
    text-decoration: $textDecoration;
}
