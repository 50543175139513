@import '../_settings/globalSetting';

.cc-recommended-grid {
    padding-top: rem-calc(23);
    padding-bottom: rem-calc(23);

    @include media-breakpoint-up(lg) {
        padding-top: rem-calc(45);
        padding-bottom: rem-calc(60);
    }

    &__carousel {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        .cc-tile-product-name {
            @include media-breakpoint-down(md) {
                max-width: 30vw;
            }
        }
    }
}
