.cc-modal-back-in-stock {
    .modal-body-wrapper {
        overflow-y: auto;
        height: calc(100vh - 247px);
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            display: none;
        }

        @include media-breakpoint-up(sm) {
            height: calc(100vh - 267px);
        }
    }

    .modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0 0 0 auto;
        max-width: rem-calc(516);
        background: $white;
        overflow-y: hidden;

        .modal-content {
            border-radius: 0;
            height: 100%;
            border: none;
        }

        .cc-pdp-modal-header {
            border: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: rem-calc(20) rem-calc(16);
            border: none;
            margin-bottom: 0;

            @include media-breakpoint-up(sm) {
                padding: rem-calc(32);
            }

            .cc-pdp-modal-header-title {
                display: block;
                font-weight: $primary-font-semibold;
                font-size: rem-calc(22);
                line-height: rem-calc(26);
                color: $black;
                margin: 0 auto 0 0;

                @include media-breakpoint-up(sm) {
                    font-size: rem-calc(24);
                    line-height: rem-calc(28);
                }
            }

            .cc-pdp-button-close {
                font-size: rem-calc(21);
                margin: 0 0 0 auto;

                &:hover {
                    @include media-breakpoint-up(md) {
                        color: $red;
                    }
                }
            }
        }

        .cc-find-store__description {
            font-size: rem-calc(13);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.13);
            margin-bottom: rem-calc(16);
            padding: 0 rem-calc(16);
            text-align: left;

            @include media-breakpoint-up(sm) {
                padding: 0 rem-calc(32);
            }

            @include media-breakpoint-up(md) {
                font-size: rem-calc(15);
                line-height: rem-calc(22);
                letter-spacing: rem-calc(0.15);
            }

            p {
                font: inherit;
                margin: 0;
            }
        }

        .cc-form-group {
            margin-bottom: rem-calc(24);
            padding: 0 rem-calc(16) rem-calc(24);
            position: relative;

            @include media-breakpoint-up(sm) {
                padding: 0 rem-calc(32) rem-calc(24);
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                width: calc(100% - #{rem-calc(32)});
                left: rem-calc(16);
                height: rem-calc(1);
                background: $warm-grey-02;

                @include media-breakpoint-up(sm) {
                    width: calc(100% - #{rem-calc(64)});
                    left: rem-calc(32);
                }
            }

            .cc-content-icon {
                position: relative;

                .cc-find-in-store-input {
                    margin: 0;
                    padding-right: rem-calc(90);
                }

                .btn-storelocator-search {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: rem-calc(13);
                    line-height: rem-calc(18);
                    font-weight: $primary-font-medium;
                    right: rem-calc(12);
                    text-transform: capitalize;
                    border-bottom: rem-calc(1) solid $black;

                    &:hover {
                        @include media-breakpoint-up(md) {
                            color: $red;
                            border-bottom: rem-calc(1) solid $red;
                        }
                    }
                }
            }

            .invalid-feedback {
                margin: rem-calc(10) rem-calc(210) 0;
            }
        }

        .cc-modal-body {
            padding: 0 rem-calc(16) rem-calc(24);

            @include media-breakpoint-up(sm) {
                padding: 0 rem-calc(32) rem-calc(24);
            }

            .no-results {
                display: block;
                font-size: rem-calc(13);
                line-height: rem-calc(18);
                font-weight: $primary-font-bold;
                text-align: left;

                @include media-breakpoint-up(sm) {
                    font-size: rem-calc(15);
                    line-height: rem-calc(22);
                }
            }
        }

        .card-body {
            display: block;
            position: relative;
            margin: 0 0 rem-calc(8);
            padding: rem-calc(16) rem-calc(12);
            width: 100%;
            background: $warm-grey-01;

            @include media-breakpoint-up(sm) {
                padding: rem-calc(16);
            }

            .store-title {
                display: block;
                font-weight: $primary-font-medium;
                font-size: rem-calc(15);
                line-height: rem-calc(22);
                letter-spacing: rem-calc(0.15);
                color: $black;
                text-align: left;
                padding-right: rem-calc(70);
                margin: 0 0 rem-calc(16);

                a {
                    &:hover {
                        @include media-breakpoint-up(md) {
                            color: $red;
                        }
                    }
                }
            }

            .store-distance {
                display: block;
                font-weight: $primary-font-regular;
                font-size: rem-calc(15);
                line-height: rem-calc(22);
                letter-spacing: rem-calc(0.13);
                color: $warm-grey-04;
                text-align: right;
                position: absolute;
                top: rem-calc(16);
                right: rem-calc(12);

                @include media-breakpoint-up(sm) {
                    right: rem-calc(16);
                }
            }

            .cc-address-wrp {
                margin: 0;
                font-weight: $primary-font-regular;
                font-size: rem-calc(13);
                line-height: rem-calc(18);
                letter-spacing: rem-calc(0.13);
                color: $black;
                text-align: left;
                margin: 0;

                @include media-breakpoint-up(sm) {
                    display: flex;
                }
            }

            .cc-address-container {
                display: block;
                font-weight: $primary-font-regular;
                font-size: rem-calc(13);
                line-height: rem-calc(18);
                letter-spacing: rem-calc(0.13);
                color: $black;
                text-align: left;
                margin: 0 0 rem-calc(12);

                @include media-breakpoint-up(sm) {
                    width: 50%;
                    flex: 0 0 50%;
                    padding-right: rem-calc(12);
                    margin: 0;
                }
            }

            .cc-advanced-info-container {
                .cc-phone {
                    display: inline-block;
                    margin-bottom: rem-calc(12);

                    @include media-breakpoint-up(sm) {
                        margin-bottom: rem-calc(8);
                    }

                    a {
                        display: inline-block;
                        border-bottom: rem-calc(1) solid $black;

                        &:hover {
                            @include media-breakpoint-up(md) {
                                color: $red;
                                border-bottom: rem-calc(1) solid $red;
                            }
                        }
                    }
                }
            }
        }

        .modal-footer {
            border: 0;
            padding: 0 rem-calc(16) rem-calc(24);

            @include media-breakpoint-up(sm) {
                padding: 0 rem-calc(32) rem-calc(24);
            }

            .cc-load-more {
                width: 100%;
                margin: 0;
            }
        }
    }
}

.cc-modal-back-in-stock {
    .modal-dialog {
        .cc-pdp-modal-header {
            padding-bottom: rem-calc(24);
        }
    }

    .backinstock-body {
        .cc-modal-description {
            line-height: rem-calc(22);
            letter-spacing: 0.01em;
            padding-bottom: rem-calc(32);
        }

        .cc-backinstock-content-form {
            .cc-form-group {
                padding: 0;
                margin-bottom: rem-calc(48);

                .cc-mandatory-label {
                    margin: rem-calc(16) 0;
                    font-size: rem-calc(11);
                    line-height: rem-calc(16);
                }

                .cc-content-input {
                    margin-bottom: rem-calc(32);
                }

                .custom-checkbox {
                    padding: rem-calc(16) 0 rem-calc(32);
                }

                .cc-button-type-1 {
                    margin-top: rem-calc(32);
                }

                &::after {
                    width: 0;
                }

                .invalid-feedback {
                    margin: 0;
                }
            }
        }
    }

    .serviceMessage {
        .cc-service-message {
            &--text {
                margin-bottom: rem-calc(48);
            }
        }
    }
}