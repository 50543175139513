#destination-size-guide-modal {
    .cc-pdp-size-guide-modal {
        &.cc-modal-animation.modal.fade {
            .modal-dialog {
                transition: transform 0.6s cubic-bezier(0, 0, 0, 1);
                transform: translate(100%, 0);
            }

            &.show {
                .modal-dialog {
                    transform: translate(0, 0);
                }
            }
        }

        .modal-dialog {
            width: 100%;
            height: 100%;
            margin: 0 0 0 auto;
            max-width: rem-calc(516);
            background: $white;

            .modal-content {
                border-radius: 0;
                height: 100%;
                border: none;

                .cc-pdp-modal-header {
                    border: 0;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding: rem-calc(20) rem-calc(16);
                    border-bottom: rem-calc(1) solid $warm-grey-02;
                    margin-bottom: 0;

                    @include media-breakpoint-up(sm) {
                        padding: rem-calc(32);
                    }

                    .cc-pdp-modal-header-title {
                        display: block;
                        font-weight: $primary-font-semibold;
                        font-size: rem-calc(22);
                        line-height: rem-calc(26);
                        color: $black;
                        margin: 0 auto 0 0;

                        @include media-breakpoint-up(sm) {
                            font-size: rem-calc(24);
                            line-height: rem-calc(28);
                        }
                    }

                    .cc-pdp-button-close {
                        font-size: rem-calc(21);
                        margin: 0 0 0 auto;

                        &:hover {
                            @include media-breakpoint-up(md) {
                                color: $red;
                            }
                        }
                    }
                }

                /* START OLD STYLE */
                .cc-container-content-asset-sizeguide {
                    height: 100%;
                    padding: rem-calc(16) rem-calc(16) rem-calc(50);
                    overflow-y: auto;
                    -ms-overflow-style: none;  /* Internet Explorer 10+ */
                    scrollbar-width: none;
                    overflow: -moz-scrollbars-none;
                    position: relative;

                    &::-webkit-scrollbar {
                        display: none;  /* Safari and Chrome */
                    }

                    @include media-breakpoint-up(sm) {
                        padding: rem-calc(32) rem-calc(32) rem-calc(50);
                    }

                    .cc-sizeguide__arrows-container {
                        display: none;
                        @include media-breakpoint-up (lg) {
                            display: flex;
                            gap: rem-calc(16);
                            position: absolute;
                            right: rem-calc(32);
                            top: rem-calc(32);

                            .cc-sizeguide__arrow {
                                &.left {
                                    &::after {
                                        background-image: url('../images/arrow-sizeguide-left.svg');
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        content: '';
                                        cursor: pointer;
                                        display: block;
                                        height: rem-calc(20);
                                        width: rem-calc(19);
                                        transition: 300ms;
                                    }

                                    &.disabled {
                                        &::after {
                                            background-image: url('../images/arrow-sizeguide-left-disabled.svg');
                                            transition: 300ms;
                                        }
                                    }

                                    &:hover {
                                        &::after {
                                            background-image: url('../images/arrow-sizeguide-left-red.svg');
                                            transition: 300ms;
                                        }
                                    }
                                }
                                &.right {
                                    &::after {
                                        background-image: url('../images/arrow-sizeguide-right.svg');
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        content: '';
                                        cursor: pointer;
                                        display: block;
                                        height: rem-calc(20);
                                        transition: 300ms;
                                        width: rem-calc(19);
                                    }

                                    &.disabled {
                                        &::after {
                                            background-image: url('../images/arrow-sizeguide-right-disabled.svg');
                                            transition: 300ms;
                                        }
                                    }

                                    &:hover {
                                        &::after {
                                            background-image: url('../images/arrow-sizeguide-right-red.svg');
                                            transition: 300ms;
                                        }
                                    }
                                }
                            }
                        }
                    }


                    .title {
                        display: none;
                    }

                    .nav {
                        &.nav-tabs {
                            border: 0;
                            font-size: rem-calc(14);
                            font-weight: $primary-font-semibold;
                            justify-content: flex-start;
                            margin-bottom: rem-calc(30);

                            .nav-item {
                                .nav-link {
                                    border: 0;
                                    margin-right: rem-calc(20);
                                    padding: 0;

                                    &:hover,
                                    &:focus,
                                    &.active {
                                        background: transparent;
                                        border-bottom: rem-calc(2) solid $black;
                                        color: inherit;
                                    }
                                }
                            }
                        }
                    }

                    .cc-measure-container {
                        border-bottom: rem-calc(1) solid $grey2;

                        @include media-breakpoint-up(sm) {
                            border-bottom: 0;
                        }

                        .btn-measure {
                            align-items: center;
                            background: transparent;
                            border: 0;
                            border-radius: 0;
                            border-top: rem-calc(1) solid $grey2;
                            color: black;
                            display: flex;
                            font-size: rem-calc(16);
                            font-weight: $primary-font-semibold;
                            justify-content: space-between;
                            padding: rem-calc(10) 0;

                            @include media-breakpoint-up(sm) {
                                border-bottom: rem-calc(1) solid $grey2;
                                border-top: 0;
                            }

                            @include media-breakpoint-up(lg) {
                                border-bottom: 0;
                            }

                            &:focus {
                                box-shadow: none;
                            }

                            .icon-arrow-up {
                                transition: all 300ms;

                                @include media-breakpoint-up(lg) {
                                    display: none;
                                }
                            }

                            &.collapsed {
                                .icon-arrow-up {
                                    transform: rotate(180deg);
                                }
                            }
                        }

                        .cc-measure-text {
                            padding: rem-calc(10) 0;
                        }

                        .collapse {
                            @include media-breakpoint-up(lg) {
                                display: block;
                            }
                        }
                    }

                    .visible-text {
                        @include media-breakpoint-up(lg) {
                            display: block;
                        }
                    }

                    .subtitle {
                        font-size: rem-calc(14);
                        font-weight: $primary-font-semibold;
                        margin-bottom: rem-calc(30);
                    }

                    .cc-table-container {
                        margin-bottom: rem-calc(20);

                        @include media-breakpoint-down(xs) {
                            margin-left: rem-calc(74);
                            overflow-x: scroll;
                        }
                    }
                    > div:not(.cc-sizeguide) {
                        table {
                            border: rem-calc(1) solid $grey2;
                            width: 100%;

                            @include media-breakpoint-down(xs) {
                                table-layout: fixed;
                            }

                            tr {
                                border: rem-calc(1) solid $grey3;
                            }

                            td,
                            th {
                                border: rem-calc(1) solid $grey3;
                                padding: rem-calc(8) rem-calc(2);
                                text-align: center;

                                &:nth-child(even) {
                                    background: $grey9;
                                }
                            }

                            th {
                                width: rem-calc(74);

                                @include media-breakpoint-down(xs) {
                                    margin-left: -4.625rem;
                                    margin-top: rem-calc(-1);
                                    position: absolute;
                                }
                            }

                            td {
                                width: rem-calc(90);
                            }
                        }
                    }

                }
                /* END OLD STYLE */
            }
        }

        .cc-sizeguide {
            //padding: rem-calc(32) rem-calc(16);
            background: $white;
            height: 100%;
            overflow-y: auto;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;
            overflow: -moz-scrollbars-none;

            &::-webkit-scrollbar {
                display: none;  /* Safari and Chrome */
            }

            @include media-breakpoint-up(sm) {
                //padding: rem-calc(32);
            }

            &__subtitle {
                display: block;
                font-weight: $primary-font-medium;
                font-size: rem-calc(18);
                line-height: rem-calc(24);
                color: $black;
                margin: 0 0 rem-calc(32);
            }

            &__table {
                padding: 0 0 rem-calc(26);
                margin: 0 0 rem-calc(32);
                border-bottom: rem-calc(1) solid $warm-grey-02;
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: $white;
                    width: rem-calc(2);
                    height: 100%;
                    z-index: 1;
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                    width: rem-calc(16);
                    height: 100%;
                    z-index: 1;
                }

                table {
                    width: calc(100%);
                    padding-right: rem-calc(16);
                    border: none;
                    margin: 0;
                    table-layout: fixed;
                    display: block;
                    padding-bottom: rem-calc(6);
                    overflow-x: auto;
                    position: relative;
                    -ms-overflow-style: none;  /* Internet Explorer 10+ */
                    scrollbar-width: none;
                    overflow: -moz-scrollbars-none;
                    border: 0!important;

                    &::-webkit-scrollbar {
                        display: none;  /* Safari and Chrome */
                    }
                }

                tr {
                    border: none;
                    background: transparent;
                    position: relative;

                    th, td {
                        min-width: rem-calc(82);
                        height: rem-calc(42);
                        border-radius: rem-calc(2);
                        background: $warm-grey-01;
                        font-weight: $primary-font-regular;
                        font-size: rem-calc(13);
                        line-height: rem-calc(18);
                        letter-spacing: rem-calc(0.13);
                        text-align: center;
                        border-radius: rem-calc(2);
                        border: rem-calc(2) solid $white;

                        @include media-breakpoint-up(sm) {
                            min-width: rem-calc(69);
                            height: rem-calc(48);
                        }
                    }

                    th {
                        background: $warm-grey-02;
                        font-weight: $primary-font-bold;
                        position: sticky;
                        top: 0;
                        left: rem-calc(1);
                        z-index: 1;
                    }
                }
            }

            &__measure {
                &__ul {
                    padding: 0;
                    margin: 0 0 rem-calc(24);
                }

                &__li {
                    list-style-type: none;
                    margin: 0 0 rem-calc(24);
                    position: relative;
                    padding: 0 0 0 rem-calc(36);

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &__number {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: rem-calc(24);
                    height: rem-calc(24);
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: rem-calc(15);
                    line-height: rem-calc(22);
                    letter-spacing: rem-calc(0.15);
                    text-align: center;
                    color: $black;
                    background: $warm-grey-01;
                    border-radius: rem-calc(2);
                }

                &__text {
                    display: block;
                    font-size: rem-calc(15);
                    line-height: rem-calc(22);
                    letter-spacing: rem-calc(0.15);
                    color: $black;
                    margin: 0;
                }

                &__img {
                    margin: 0 0 rem-calc(32);

                    img {
                        display: block;
                        width: rem-calc(215);
                        margin: 0 auto;
                    }
                }

                &__links {
                    &__wrp {
                        display: flex;
                        margin: 0 0 rem-calc(18);

                        &:last-child {
                            margin: 0;
                        }
                    }
                }

                &__link {
                    display: flex;
                    align-items: center;
                    text-align: left;

                    img {
                        display: block;
                        width: rem-calc(24);
                        height: rem-calc(24);
                    }

                    span {
                        display: block;
                        font-size: rem-calc(15);
                        line-height: rem-calc(22);
                        letter-spacing: rem-calc(0.15);
                        color: $black;
                        font-weight: $primary-font-medium;
                        margin: 0 0 0 rem-calc(8);
                        border-bottom: rem-calc(1) solid $black;
                    }

                    &:hover {
                        @include media-breakpoint-up(md) {
                            span {
                                color: $red;
                                border-color: $red;
                            }
                        }
                    }
                }
            }

        }
    }
}

