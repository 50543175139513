@import '../_settings/globalSetting';

@mixin original-and-pp-price-container ($fontSize: 13, $fontSizeSmaller: 13, $lineHeight: 18, $marginLeft: 3, $justifyContent: center) {

    .cc-original-price-container,
    .cc-pp-price-container {
        font-size: rem-calc($fontSize);
        font-weight: 400;
        font-style: normal;
        line-height: rem-calc($lineHeight);
        color: #919496;

        @include media-breakpoint-down(xs) {
            font-size: rem-calc($fontSizeSmaller);
        }

        display: flex;
        flex-direction: row;
        justify-content: $justifyContent;
        align-items: center;
        flex-wrap: wrap;
    }

    .cc-pp-price-container {

        .cc-pp-price-text {
            margin-right: rem-calc(4);
        }

        .cc-pp-price {
            text-decoration: line-through;
        }

        .cc-pp-price-percentage {
            font-weight: 500;
            margin-left: rem-calc($marginLeft);
        }
    }

    .cc-original-price-container {

        .cc-original-price-text {
            margin-right: rem-calc(4);
        }

        .cc-original-price {
            display: inline-block;
            text-decoration: line-through;
        }

        .cc-original-price-percentage {
            font-weight: 400;
            margin-left: rem-calc($marginLeft);
        }
    }

    .sales {
        &.cc-sales-reduced {
            color: $red1;
        }
    }
}
